import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../pages/Dashboard.vue'
import LayoutDefault from '../layouts/Default.vue'
import LayoutCenter from '../layouts/Center.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/invoices',
    name: 'invoice-list',
    component: () => import('../pages/InvoiceList.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/invoices/:id/edit',
    name: 'invoice-edit',
    component: () => import('../pages/InvoiceEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/recurring-donations',
    name: 'recurring-list',
    component: () => import('../pages/SubscriptionList.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/recurring-donations/:id/edit',
    name: 'recurring-edit',
    component: () => import('../pages/SubscriptionEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/campaigns',
    name: 'campaign-list',
    component: () => import('../pages/CampaignList.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/campaigns/create',
    name: 'campaign-create',
    component: () => import('../pages/CampaignCreate.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/campaigns/edit/:id',
    name: 'campaign-edit',
    component: () => import('../pages/CampaignEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/products',
    name: 'product-list',
    component: () => import('../pages/ProductList.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/products/create',
    name: 'product-create',
    component: () => import('../pages/ProductCreate.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/products/:id/edit/',
    name: 'product-edit',
    component: () => import('../pages/ProductEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/product-categories',
    name: 'category-list',
    component: () => import('../pages/ProductCategoryList.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/product-categories/create',
    name: 'category-create',
    component: () => import('../pages/ProductCategoryCreate.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/product-categories/edit/:id',
    name: 'category-edit',
    component: () => import('../pages/ProductCategoryEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/ebooks',
    name: 'ebook-list',
    component: () => import('../pages/EbookList.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/ebooks',
    name: 'ebook-create',
    component: () => import('../pages/EbookCreate.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/ebooks/:id',
    name: 'ebook-edit',
    component: () => import('../pages/EbookEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../pages/Dashboard.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/users',
    name: 'user-list',
    component: () => import('../pages/UserList.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/users/create',
    name: 'user-create',
    component: () => import('../pages/UserCreate.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/users/edit/:id',
    name: 'user-edit',
    component: () => import('../pages/UserEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },

  {
    path: '/profile',
    name: 'profile',
    component: () => import('../pages/ProfileEdit.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../pages/PasswordChange.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue'),
    meta: {
      layout: LayoutCenter,
      requireGuest: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../pages/Logout.vue'),
    meta: {
      layout: LayoutDefault,
      requireAuthen: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

const middlewareAuthen = (to, from, next) => {
  // require authen but not authenticated.
  if (to.meta && to.meta.requireAuthen && !store.getters['auth/isAuthenticated']) {
    return router.push({ name: 'login' })
  }

  // require guest but authenticated.
  console.log(to.meta.requireGuest, store.getters['auth/isAuthenticated'])
  if (to.meta && to.meta.requireGuest && store.getters['auth/isAuthenticated']) {
    return router.push({ name: 'home' })
  }
  next()
}

const middlewareScrollTop = (to, from, next) => {
  window.scrollTo(0, 0)
  next()
}

router.beforeEach(middlewareAuthen)
router.beforeEach(middlewareScrollTop)

export default router
