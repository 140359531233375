import axios from 'axios'

class FileService {
  list (criteria = {}) {
    return axios.get('files', { params: criteria })
  }

  upload (data) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axios.post('files', data, { headers })
  }

  delete (id) {
    return axios.delete(`files/${id}`)
  }
}

export default FileService
