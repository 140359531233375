export default {
  namespaced: true,

  state: {
    fileBrowser: {
      visible: false,
      params: {}
    }
  },

  mutations: {
    SET_FILE_BROWSER_VISIBILITY (state, visible) {
      state.fileBrowser.visible = visible
    },

    SET_FILE_BROWSER_PARAMS (state, params) {
      state.fileBrowser.params = params
    }
  },

  actions: {
    openFileBrowser ({ commit }, params) {
      commit('SET_FILE_BROWSER_VISIBILITY', true)
      commit('SET_FILE_BROWSER_PARAMS', params)
    },

    closeFileBrowser ({ commit }) {
      commit('SET_FILE_BROWSER_VISIBILITY', false)
    }
  },

  getters: {}
}
