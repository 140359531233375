import axios from 'axios'

class SubscriptionService {
  list (criteria = {}) {
    return axios.get('recurring-donations', { params: criteria })
  }

  get (id) {
    return axios.get(`recurring-donations/${id}`)
  }

  updateStatus (id, data) {
    return axios.patch(`recurring-donations/${id}/status`, data)
  }

  updateReceipt (id, data) {
    return axios.patch(`recurring-donations/${id}/receipt`, data)
  }
}

export default SubscriptionService
