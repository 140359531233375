import axios from 'axios'

class AuthService {
  login ({ email, password }) {
    return axios.post('auth/login', { email, password })
  }

  logout () {
    return axios.post('auth/logout')
  }
}

export default AuthService
