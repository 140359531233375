<template>
  <div id="app" class="w-100" :key="$route.fullPath">
    <component :is="layout">
      <router-view/>
    </component>

    <file-browser />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FileBrowser from './components/FileBrowser.vue'

export default {
  name: 'App',

  components: {
    FileBrowser
  },

  computed: {
    layout () {
      return this.$route.meta.layout
    }
  },

  created () {
    this.authInit()
  },

  methods: {
    ...mapActions({
      authInit: 'auth/init'
    })
  }
}
</script>

<style lang="scss">
</style>
