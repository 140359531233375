<template>
  <b-modal
    size="xl"
    title="File Browser"
    no-fade
    scrollable
    class="file-browser"
    :visible="fileBrowserVisible"
    @hide="hide"
  >
    <div class="file-browser-wrapper">
      <ul class="file-list">
        <li
          v-for="file in files"
          :key="file.id"
          class="file-item"
          :class="{ selected: isSelected(file) }"
          @click="selectFile(file)"
        >
          <div class="file-preview">
            <div class="thumbnail">
              <div class="centered">
                <img :src="getUrl(file.path)" class="icon">
              </div>
              <div
                v-if="!file.mimetype.startsWith('image/')"
                class="filename"
              >
                {{ file.name }}
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="file-detail">
        <div
          v-if="currentSelectedFile"
          class=""
        >
          {{ currentSelectedFile.name }}<br>
          {{ currentSelectedFile.created_at | dateFormat('dd MMMM yyyy') }}<br>
          {{ currentSelectedFile.size }} bytes <br>
          <b-button
            variant="danger"
            @click="openDeleteDialog"
          >
            Delete
          </b-button>
        </div>
      </div>
    </div>

    <!-- Header -->
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center">
        <h5 class="modal-title mr-3">File Browser</h5>
        <div class="upload-file-field">
          <label for="upload-file" class="btn btn-primary mb-0">
            <b-icon-upload />
            Upload Files
          </label>
          <input
            id="upload-file"
            type="file"
            @change="onFileUploadChanged"
          >
        </div>
      </div>
      <button type="button" class="close" @click="close">&times;</button>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-button
        variant="primary"
        :disabled="!hasSelectedFiles"
        @click="applySelection"
      >
        {{ applySelectionButtonText }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FileBrowser',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      fileBrowserVisible: state => state.modal.fileBrowser.visible,
      fileBrowserParams: state => state.modal.fileBrowser.params
    }),

    hasSelectedFiles () {
      return this.numSelectedFiles > 0
    },

    numSelectedFiles () {
      return this.selectedFiles.length
    },

    applySelectionButtonText () {
      if (this.hasSelectedFiles) {
        return `Apply ${this.numSelectedFiles} selection`
      }
      else {
        return 'Apply selection'
      }
    },

    currentSelectedFile () {
      return this.selectedFiles[this.selectedFiles.length - 1]
    }
  },

  data () {
    return {
      files: [],
      selectedFiles: []
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    async fetch () {
      try {
        const resp = await this.$files.list()
        this.files = resp.data
      }
      catch (e) {
        console.error(e.message)
      }
    },

    getUrl (path) {
      return `${process.env.VUE_APP_MEDIA_URL}/${path}`
    },

    hide () {
      this.$store.dispatch('modal/closeFileBrowser')
      this.selectCallback = null
      this.selectedFiles = []
    },

    isSelected (file) {
      return this.selectedFiles.find(selectedFile => selectedFile.id === file.id)
    },

    async onFileUploadChanged (e) {
      const files = e.target.files
      if (files.length === 0) {
        return
      }

      const formData = new FormData()
      for (let file of files) {
        formData.append('files[]', file)
      }
      try {
        await this.$files.upload(formData)
        this.fetch()
      }
      catch (e) {
        console.error(e.message)
      }
    },

    selectFile (file) {
      if (this.fileBrowserParams.multiple) {
        const index = this.selectedFiles.findIndex(selectedFile => selectedFile.id === file.id)
        if (index >= 0) {
          this.selectedFiles.splice(index, 1)
        }
        else {
          this.selectedFiles.push(file)
        }
      }
      else {
        this.selectedFiles = [file]
      }
    },

    applySelection () {
      if (!this.fileBrowserParams.callback) {
        return
      }

      const files = this.selectedFiles.map(selectedFile => ({
        name: selectedFile.name,
        path: selectedFile.path,
        mimetype: selectedFile.mimetype
      }))

      this.fileBrowserParams.callback(files)
      this.hide()
    },

    openDeleteDialog () {
      this.$bvModal.msgBoxConfirm(
        'You are about to permanently delete this item. This action cannot be undone',
        {
          title: 'Delete file',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel'
        }
      ).then(value => {
        if (value) {
          return this.$files.delete(this.currentSelectedFile.id)
        }
      }).then(() => {
        this.fetch()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.file-browser-wrapper {
  display: flex;
  flex-direction: row;
}

.file-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: calc(100% - 300px);

  .file-item {
    width: 16.66%;
    position: relative;
    float: left;
    padding: 8px;
    margin: 0;
    color: #444;
    cursor: pointer;
    list-style: none;
    text-align: center;
    user-select: none;
    box-sizing: border-box;

    &.selected {
      box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 7px #0073aa;
    }

    .file-preview {
      position: relative;
      box-shadow: inset 0 0 15px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 5%);
      background: #eee;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }

    .thumbnail {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      transition: opacity .1s;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
        overflow: hidden;
      }
    }

    .centered {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translate(50%, 50%);

      img {
        position: absolute;
        top: 0;
        left: 0;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .filename {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      max-height: 100%;
      word-wrap: break-word;
      text-align: center;
      font-weight: 600;
      background: rgba(255,255,255,.8);
      box-shadow: inset 0 0 0 1px rgb(0 0 0 / 15%);
      font-size: 12px;
      padding: 5px 10px;
    }
  }
}

.file-detail {
  background-color: #efefef;
  border-right: 1px solid #eee;
  width: 300px;
}

.upload-file-field {
  input[type=file] {
    display: block;
    width: 0px;
    height: 0px;
    text-indent: -9999px;
  }
}
</style>
