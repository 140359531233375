import Vue from 'vue'

Vue.mixin({
  computed: {
    locales () {
      return this.$i18n.availableLocales
    },

    currentLocale () {
      return this.$i18n.locale
    }
  }
})
