import Vue from 'vue'
import { createResourceService } from '../services'
import AuthService from '../services/auth.service.js'
import DashboardService from '../services/dashboard.service.js'
import FileService from '../services/file.service.js'
import InvoiceService from '../services/invoice.service'
import ProfileService from '../services/profile.service'
import SubscriptionService from '../services/subscription.service'

Vue.use({
  install (v) {
    v.prototype.$auth = v.$auth = new AuthService()
    v.prototype.$campaigns = v.$campaigns = createResourceService('campaigns')
    v.prototype.$categories = v.$categories = createResourceService('product-categories')
    v.prototype.$dashboard = v.$dashboard = new DashboardService()
    v.prototype.$files = v.$files = new FileService()
    v.prototype.$invoices = v.$invoices = new InvoiceService()
    v.prototype.$products = v.$products = createResourceService('products')
    v.prototype.$profile = v.$profile = new ProfileService()
    v.prototype.$subscriptions = v.$subscriptions = new SubscriptionService()
    v.prototype.$users = v.$users = createResourceService('users')
    v.prototype.$ebooks = v.$ebooks = createResourceService('ebooks')
  }
})
