import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Plugins.
import './plugins/axios.js'
import './plugins/bootstrap-vue.js'
import i18n from './plugins/i18n.js'
import './plugins/mixins.js'
import './plugins/services.js'

// Stylesheet.
import './assets/scss/app.scss'

import './filter.js'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
