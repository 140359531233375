import axios from 'axios'

export function createResourceService (resourceName) {
  return {
    list (criteria = {}) {
      return axios.get(resourceName, { params: criteria })
    },
  
    get (id) {
      return axios.get(`${resourceName}/${id}`)
    },
  
    create (data) {
      return axios.post(resourceName, data)
    },
  
    update (id, data) {
      return axios.put(`${resourceName}/${id}`, data)
    },
  
    delete (id) {
      return axios.delete(`${resourceName}/${id}`)
    }
  }
}
