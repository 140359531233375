<template>
  <span class="number">{{ text }}</span>
</template>

<script>
export default {
  name: 'Number',

  props: {
    amount: {
      type: Number,
      required: true
    },

    appendText: {
      type: String,
      default: null
    },

    prependText: {
      type: String,
      default: null
    }
  },

  computed: {
    text () {
      const chunks = []

      if (this.prependText) {
        chunks.push(this.prependText)
      }

      chunks.push(this.commaizeAmount)

      if (this.appendText) {
        chunks.push(this.appendText)
      }

      return chunks.join('')
    },

    commaizeAmount () {
      return this.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }
  },

  methods: {
    commaize () {
      return
    }
  }
}
</script>

<style lang="scss">
</style>
