import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../translations/en.js'
import th from '../translations/th.js'

Vue.use(VueI18n)

const messages = { en, th }

const i18n = new VueI18n({
  locale: 'th',
  fallbackLocale: 'en',
  messages
})

export default i18n
