<template>
  <div class="layout-default">
    <!-- BEGIN: Header -->
    <header class="navbar navbar-expand-lg navbar-light bg-primary fixed-top">
      <b-navbar-brand :to="{ name: 'dashboard' }" class="px-0 py-0 mx-0 my-0">
        <img src="/img/unicef-logo.png" alt="Unicef Logo" srcset="/img/unicef-logo@2x.png 2x" style="height: 50px;" class="img-fluid">
      </b-navbar-brand>

      <b-navbar-nav
        v-if="user"
        class="ml-auto"
      >
        <b-nav-item-dropdown>
          <template #button-content>
            {{ user.name }}
          </template>

          <b-dropdown-item :to="{ name: 'profile' }">Edit Profile</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'change-password' }">Change Password</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'logout' }">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </header>
    <!-- END: Header -->

    <div class="container-fluid">
      <div class="row flex-xl-nowrap">
        <!-- BEGIN: Sidebar -->
        <div class="col-md-4 col-xl-3 sidebar">
          <navigation />
        </div>
        <!-- END: Sidebar -->

        <!-- BEGIN: Main Content -->
        <main class="col-md-8 col-xl-9 py-4" role="main">
          <slot />
        </main>
        <!-- END: Main Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Navigation from '../components/Navigation'

export default {
  name: 'LayoutDefault',

  components: {
    Navigation
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
