import Vue from 'vue'
import axios from 'axios'
import { storage } from '../util.js'

export default {
  namespaced: true,

  state: {
    user: storage.get('user'),
    token: storage.get('token')
  },

  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      storage.set('token', token)
    },

    SET_USER (state, user) {
      state.user = user
      storage.set('user', user)
    }
  },

  actions: {
    init ({ commit }) {
      const token = storage.get('token')
      if (token) {
        commit('SET_TOKEN', storage.get('token'))
      }

      const user = storage.get('user')
      if (user) {
        commit('SET_USER', storage.get('user'))
      }
    },

    async login ({ commit }, payload) {
      const resp = await Vue.$auth.login(payload)
      commit('SET_TOKEN', resp.data.access_token)
      commit('SET_USER', resp.data.user)
      return resp
    },

    async logout ({ commit }) {
      const resp = await Vue.$auth.logout()
      commit('SET_TOKEN', null)
      commit('SET_USER', null)
      return resp
    }
  },

  getters: {
    isAuthenticated (state) {
      return !!state.token
    },

    user (state) {
      return state.user
    }
  }
}
