import axios from 'axios'

class InvoiceService {
  list (criteria = {}) {
    return axios.get('invoices', { params: criteria })
  }

  get (id) {
    return axios.get(`invoices/${id}`)
  }

  addComment (id, data) {
    return axios.post(`invoices/${id}/comment`, data)
  }

  updateStatus (id, data) {
    return axios.patch(`invoices/${id}/status`, data)
  }

  updateReceipt (id, data) {
    return axios.patch(`invoices/${id}/receipt`, data)
  }
}

export default InvoiceService
