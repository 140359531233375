import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from '../store'
import router from '../router'

Vue.use(VueAxios, axios)

// Set default API URL.
axios.defaults.baseURL = process.env.VUE_APP_API_URL

// Handle response.
axios.interceptors.response.use(response => response, error => {
  if (error.response) {
    // Unauthorized.
    if (error.response.status === 401) {
      store.dispatch('auth/logout')
      router.push({ name: 'logout' })
    }
  }
  return Promise.reject(error)
})
