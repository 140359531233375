<template>
  <div class="dashboard-page">
    <h2>Dashboard</h2>

    <b-row class="mt-3">
      <b-col>
        <div class="bg-primary text-white px-3 py-3">
          <div class="mb-4">Total amount</div>
          <div class="h3 mb-0 text-right">
            <number :amount="data.total.sum" prepend-text="฿ " />
          </div>
        </div>
      </b-col>

      <b-col>
        <div class="bg-warning text-white px-3 py-3">
          <div class="mb-4">Total donate</div>
          <div class="text-right">
              <span class="h3 mb-0">
                <number :amount="data.total.count" />
              </span>
              <small>&nbsp;ครั้ง</small>
          </div>
        </div>
      </b-col>

      <b-col>
        <div class="bg-success text-white px-3 py-3">
          <div class="mb-4">Average</div>
          <div class="text-right">
              <span class="h3 mb-0">
                <number :amount="data.total.avg" prepend-text="฿ " />
              </span>
              <small>&nbsp;/ ครั้ง</small>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-card class="mt-3">
      <b-row>
        <b-col md="3">
          <div class="bg-primary text-white px-3 py-3 mb-2">
            <div class="mb-4">Total amount</div>
            <div class="h3 mb-0 text-right">
              <number :amount="data.total.sum" prepend-text="฿ " />
            </div>
          </div>

          <div class="bg-warning text-white px-3 py-3 mb-2">
            <div class="mb-4">Total donate</div>
            <div class="text-right">
                <span class="h3 mb-0">
                  <number :amount="data.total.count" />
                </span>
                <small>&nbsp;ครั้ง</small>
            </div>
          </div>
          <div class="bg-success text-white px-3 py-3">
            <div class="mb-4">Average</div>
            <div class="text-right">
                <span class="h3 mb-0">
                  <number :amount="data.total.avg" prepend-text="฿ " />
                </span>
                <small>&nbsp;/ ครั้ง</small>
            </div>
          </div>
        </b-col>

        <b-col md="9">
          <canvas ref="chart"></canvas>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Chart from 'chart.js'
import Number from '../components/Number'

export default {
  name: 'Dashboard',

  components: {
    Number
  },

  computed: {
    chartElement () {
      return this.$refs.chart
    }
  },

  data () {
    return {
      chart: null,
      data: {
        data: {
          datasets: [],
          labels: []
        },
        total: {
          sum: 0,
          count: 0,
          avg: 0,
        }
      }
    }
  },

  mounted () {
    this.createChart()
    this.fetch()
  },

  methods: {
    async fetch () {
      try {
        const resp = await this.$dashboard.get()
        this.data = resp.data.data
        this.updateChart()
      }
      catch (e) {
        console.error(e.message)
      }
    },

    createChart () {
      this.chart = new Chart(this.chartElement, {
        type: 'line',
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          tooltips: {
              mode: 'index',
              intersect: false,
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Date'
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Value'
              }
            }]
          }
        }
      })
    },

    updateChart () {
      this.chart.data.datasets = this.data.data.datasets
      this.chart.data.labels = this.data.data.labels
      this.chart.update()
    }
  }
}
</script>
