<template>
  <nav class="navigation">
    <!-- Dashboard -->
    <div class="nav-section">
      <ul class="nav">
        <li><router-link :to="{ name: 'dashboard' }">Dashboard</router-link></li>
      </ul>
    </div>

    <!-- Donations -->
    <div class="nav-section">
      <div class="nav-section-title">Donations</div>
      <ul class="nav">
        <li><router-link :to="{ name: 'invoice-list' }">Invoices</router-link></li>
        <li><router-link :to="{ name: 'recurring-list' }">Recurring Donations</router-link></li>
      </ul>
    </div>

    <!-- Campaigns -->
    <div class="nav-section">
      <div class="nav-section-title">Content Management</div>
      <ul class="nav">
        <li><router-link :to="{ name: 'campaign-list' }">Campaigns</router-link></li>
        <li><router-link :to="{ name: 'product-list' }">Products</router-link></li>
        <li><router-link :to="{ name: 'category-list' }">Product Categories</router-link></li>
        <li><router-link :to="{ name: 'ebook-list' }">Ebooks</router-link></li>
      </ul>
    </div>

    <!-- System -->
    <div class="nav-section">
      <div class="nav-section-title">System</div>
      <ul class="nav">
        <li><router-link :to="{ name: 'settings' }">Site settings</router-link></li>
        <li><router-link :to="{ name: 'user-list' }">Users</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<style scoped lang="scss">
</style>
