const storage = {
  get (key, defaultValue = null) {
    const value = window.localStorage.getItem(key)
    if (value !== null && value !== undefined && value.toString().length > 0) {
      return JSON.parse(value)
    }
    return defaultValue
  },

  set (key, value) {
    if (value === undefined || value === null) {
      window.localStorage.removeItem(key)
    }
    else {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }
}

export {
  storage
}
