import Vue from 'vue'
import {
  BootstrapVue,
  BIconChevronLeft,
  BIconChevronRight,
  BIconPlus,
  BIconSearch,
  BIconUpload,
  BIcon
} from 'bootstrap-vue'

Vue.use(BootstrapVue)

/**
 * Register icon components.
 */
Vue.component('BIcon', BIcon)
Vue.component('BIconChevronLeft', BIconChevronLeft)
Vue.component('BIconChevronRight', BIconChevronRight)
Vue.component('BIconPlus', BIconPlus)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconUpload', BIconUpload)
