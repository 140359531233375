import axios from 'axios'

class ProfileService {
  get () {
    return axios.get('profile')
  }

  update (data) {
    return axios.post('profile', data)
  }

  updatePassword (data) {
    return axios.post('profile/password', data)
  }
}

export default ProfileService
