<template>
  <div class="layout-center container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LayoutCenter'
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

body.center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  background-color: #f5f5f5;
}
</style>